/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
// import Image from "components/general/Image";
import Text from "components/general/Text";
import Icon from "components/general/Icon";
import {
  useSendLocationStampMutation,
  useUpdateVisitMutation,
} from "apis/services/visits";
import LoadingIndicator from "components/general/LoadingIndicator";
import HandleErrors from "utils/handleErrors";
import { MarkVisitProps } from "./types";
import styles from "./styles.module.scss";

function SuccessMarked({
  onConfirm,
  visitNumber,
}: {
  onConfirm: any;
  visitNumber: number;
}) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onConfirm();
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={styles.successContainer}>
      <div className={styles.successIcontContainer}>
        <Icon name="success" size={104} color="primary" />
      </div>
      <Text className={styles.title}>Visit marked as completed</Text>
      <Text className={styles.secondaryTitle}>
        you can find this visit number #{visitNumber} in visit history page
      </Text>
    </div>
  );
}

export default function MarkVisit({
  onConfirm,
  isVisible = false,
  setIsVisible,
  isLoading,
  clientName,
  // logo,
  visitNumber,
  visitId,
}: MarkVisitProps) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [sendLocationStamp, { isLoading: isSendLocationLoading }] =
    useSendLocationStampMutation();
  const [updateVisit, { isLoading: isUpdateVisitLoading }] =
    useUpdateVisitMutation();

  if (!isVisible) return null;

  const showError = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert(
          "This site's Location permission is  denied. Please go to site settings and open location permission to be able to mark visits"
        );
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        break;
    }
  };

  const handleVisitMark = async (data: any) => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        sendLocationStamp({
          lat: String(position.coords.latitude),
          lng: String(position.coords.longitude),
        })
          .unwrap()
          .then((res) => {
            const formData = new FormData();
            formData.append("completed_date", res.timestamp);
            if (data.note) formData.append("note", data.note);
            formData.append("on_complete_map_stamp", String(res.id));

            updateVisit([visitId, formData]);
          })
          .finally(() => setIsSuccess(true))
          .catch((err) => HandleErrors(err)),
      showError
    );
  };

  return (
    <Modal
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      className={styles.modal}
      footer={null}
    >
      {isSuccess ? (
        <SuccessMarked onConfirm={onConfirm} visitNumber={visitNumber} />
      ) : (
        <div className={styles.modalBox}>
          <div className={styles.companyLogoAndTitle}>
            {/* <div className={styles.logoContainer}>
              <Image src={logo} alt="company-logo" />
            </div> */}
            <div className={styles.titlesContainer}>
              <Text className={styles.title}>{clientName}</Text>
              <Text className={styles.secondaryTitle}>
                Order Num: #{visitNumber}
              </Text>
            </div>
          </div>
          {isSendLocationLoading || isUpdateVisitLoading ? (
            <LoadingIndicator />
          ) : (
            <Form
              className={styles.form}
              autoComplete="off"
              autoCorrect="off"
              onFinish={handleVisitMark}
            >
              <div className={styles.body}>
                <Text>Add Note</Text>
                <Form.Item name="note">
                  <TextInput
                    textareaSize={{ minRows: 3, maxRows: 3 }}
                    type="textarea"
                  />
                </Form.Item>
              </div>
              <div className={styles.footer}>
                <Button
                  type="submit"
                  btnClassName={styles.markBtn}
                  isLoading={isLoading}
                >
                  Mark as Complete
                </Button>
              </div>
            </Form>
          )}
        </div>
      )}
    </Modal>
  );
}
