import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "reducers";
import loginHandler from "utils/loginHandler";

export default function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    const savedToken = localStorage.getItem("token") || "";
    const savedRefreshToken = localStorage.getItem("refreshToken") || "";

    loginHandler({
      token: savedToken,
      refreshToken: savedRefreshToken,
      withoutResetCache: true,
    });
  }, []);

  useEffect(() => {
    if (typeof token !== "string") return;

    if (!token) {
      navigate("/auth");
    } else {
      const path = location.pathname.includes("auth") ? "/" : location.pathname;
      navigate(`${path}${location.search}`);
    }
  }, [token]);
}
