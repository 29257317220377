import api from "apis";
import {
  AuthTokenResponse,
  CheckResetCodeBody,
  ForgotPasswordResponse,
  GetResetCodeBody,
  LoginBody,
  ResetPassword,
  UserInfo,
} from "../types/auth";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query<UserInfo, void>({
      query: () => ({
        url: "/users/get_me/",
      }),
      providesTags: ["User"],
    }),

    login: build.mutation<AuthTokenResponse, LoginBody>({
      query: (body) => ({
        url: "/users/login/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    getResetCode: build.mutation<ForgotPasswordResponse, GetResetCodeBody>({
      query: (body) => ({
        url: "/users/password/send_reset_email/",
        method: "POST",
        body,
      }),
    }),

    checkResetCode: build.mutation<unknown, CheckResetCodeBody>({
      query: (body) => ({
        url: "/users/password/check_reset_code/",
        method: "POST",
        body,
      }),
    }),

    resetPassword: build.mutation<unknown, ResetPassword>({
      query: (body) => ({
        url: "/users/password/reset_password/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useLoginMutation,
  useCheckResetCodeMutation,
  useGetResetCodeMutation,
  useResetPasswordMutation,
} = authApi;
