import { useGetUserInfoQuery } from "apis/services/auth";
import { useSelector } from "react-redux";
import { RootState } from "reducers";

export default function useGetUserInfo() {
  const token = useSelector((state: RootState) => state.auth.token);

  const { data: userInfo, isFetching: isUserInfoLoading } = useGetUserInfoQuery(
    undefined,
    { skip: !token }
  );

  return {
    isClient: userInfo?.role === "client",
    isLeadEmployee: userInfo?.employee_type
      ? userInfo?.role === "employee" && userInfo.employee_type === "lead"
      : false,
    userInfo,
    isUserInfoLoading,
  };
}
