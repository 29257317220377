import { Input } from "antd";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";
import { TextInputProps } from "./types";

export default function TextInput({
  containerStyle,
  inputSuffix,
  inputPrefix,
  outerSuffix,
  name,
  value,
  onChange,
  placeholder,
  i18nPlaceholder,
  label,
  i18nLabelKey,
  type = "text",
  isNumberInput = false,
  textareaSize = { minRows: 6, maxRows: 6 },
}: TextInputProps) {
  const { t } = useAutoCompleteTranslation();

  const TargetInputTypeMap = new Map<string, any>([
    ["password", Input.Password],
    ["textarea", Input.TextArea],
    ["text", Input],
  ]);

  const TargetInput = TargetInputTypeMap?.get(type);

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      {(!!label || !!i18nLabelKey) && (
        <span className={styles.label}>
          {i18nLabelKey ? t(i18nLabelKey) : label}
        </span>
      )}

      <div className={styles.inputContent}>
        <TargetInput
          name={name}
          className={styles.input}
          placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
          suffix={inputSuffix}
          prefix={inputPrefix}
          value={value}
          onChange={(e: any) => {
            onChange?.(
              isNumberInput
                ? {
                    ...e,
                    target: { ...e.target, value: Number(e.target.value) },
                  }
                : e
            );
          }}
          autoSize={textareaSize}
          type={isNumberInput ? "number" : undefined}
        />

        {outerSuffix}
      </div>
    </div>
  );
}
