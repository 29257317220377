import IcoMoon from "react-icomoon";
import COLORS from "constants/Colors";
import iconSet from "assets/Iconmoon/selection.json";
import { IconProps } from "./types";

export default function Icon({ name, size, color }: IconProps) {
  return (
    <IcoMoon
      iconSet={iconSet}
      icon={name}
      size={size}
      color={color && COLORS[color]}
    />
  );
}
