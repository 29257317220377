import { toast } from "react-toastify";

export default function HandleErrors(err: any) {
  const showErrorToast = (msg: string) => {
    toast.error(msg);
  };

  if (err?.code?.includes("token_not_valid")) {
    showErrorToast("Your session has expired. Please Login!");
  } else if (err.details?.reason) {
    showErrorToast(err.details?.reason);
  } else if (typeof err.detail === "string") {
    showErrorToast(err.detail);
  } else if (err.message) {
    showErrorToast(err.message);
  } else if (err.details?.length) {
    showErrorToast(err.details.join(", "));
  } else if (err.detail) {
    showErrorToast(err.detail);
  } else if (err.user?.email) {
    showErrorToast(err.user?.email?.[0]);
  } else if (Array.isArray(err)) {
    showErrorToast(err.join(", "));
  } else if (err && Object.keys(err)) {
    showErrorToast(err[Object.keys(err)[0]][0]);
  } else if (Object.keys(err)?.length !== 0) {
    showErrorToast(String(Object.values(err)[0]));
  } else {
    showErrorToast("Unexpected Error!");
  }
}
