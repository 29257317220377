import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { ConfigProvider, ThemeConfig } from "antd";
import COLORS from "constants/Colors";
import useAuth from "hooks/useAuth";
import { useSendLocationStampMutation } from "apis/services/visits";
import useGetUserInfo from "hooks/useGetUserInfo";
import "i18n";
import styles from "./styles.module.scss";

const config: ThemeConfig = {
  token: {
    colorPrimary: COLORS.primary,
  },
};

export default function MainLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useAuth();

  const { userInfo } = useGetUserInfo();
  const [sendLocationStamp] = useSendLocationStampMutation();

  useEffect(() => {
    if (userInfo?.role === "employee") {
      const showError = (error: any) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            alert(
              "This site's Location permission is denied. Please go to site settings and open location permission to be able to use this site"
            );
            break;
          case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            alert("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.");
            break;
          default:
            break;
        }
      };

      navigator.geolocation.getCurrentPosition(
        (position) =>
          sendLocationStamp({
            lat: String(position.coords.latitude),
            lng: String(position.coords.longitude),
          }),
        showError
      );
    }
  }, []);

  return (
    <ConfigProvider theme={config}>
      <div className={styles.mainContainer}>
        {children}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ConfigProvider>
  );
}
