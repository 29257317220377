import api from "apis";
import { PaginatedResponse } from "apis/types/general";
import { Mapstamp, Visit } from "apis/types/visits";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";
import infintyPaginationMergeHandler from "utils/infintyPaginationMergeHandler";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllVisits: build.query<
      PaginatedResponse<Visit>,
      {
        is_completed?: boolean;
        is_client_validated_and_report_uploaded?: boolean;
        is_client_validated?: boolean;
        is_report_uploaded?: boolean;
        page: number;
        scheduled_date?: string;
        scheduled_date_gte?: string;
        scheduled_date_lte?: string;
        project_id?: string;
      }
    >({
      query: (params) => ({
        url: "/visits/",
        params: { ...params },
      }),
      providesTags: ["Visits"],
      serializeQueryArgs: getSerializedQueryArgs,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
    }),

    sendLocationStamp: build.mutation<
      Mapstamp,
      Omit<Mapstamp, "id" | "timestamp">
    >({
      query: (body) => ({
        url: "/mapstamp/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    updateVisit: build.mutation<any, [id: number, body: any]>({
      query: ([id, body]) => ({
        url: `/visits/${id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Visits"],
    }),
  }),
});

export const {
  useGetAllVisitsQuery,
  useSendLocationStampMutation,
  useUpdateVisitMutation,
} = authApi;
