import { Link } from "react-router-dom";
import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import loginHandler from "utils/loginHandler";
import Image from "components/general/Image";
import LOGO from "assets/images/slg-logo.png";
import { useLazyGetUserInfoQuery, useLoginMutation } from "apis/services/auth";
import { useSendLocationStampMutation } from "apis/services/visits";
import HandleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

export default function Login() {
  const [login, { isLoading }] = useLoginMutation();
  const [getUserInfo] = useLazyGetUserInfoQuery();
  const [sendLocationStamp] = useSendLocationStampMutation();

  const onLoginHandler = (values: any) => {
    login({ email: values.email, password: values.password })
      .unwrap()
      .then((res) => {
        loginHandler({
          token: res.access_token,
          refreshToken: res.refersh_token,
        });
      })
      .then(() => {
        getUserInfo()
          .unwrap()
          .then((res) => {
            if (res?.role === "employee") {
              const showError = (error: any) => {
                switch (error.code) {
                  case error.PERMISSION_DENIED:
                    alert(
                      "This site's Location permission is denied. Please go to site settings and open location permission to be able to use this site"
                    );
                    break;
                  case error.POSITION_UNAVAILABLE:
                    alert("Location information is unavailable.");
                    break;
                  case error.TIMEOUT:
                    alert("The request to get user location timed out.");
                    break;
                  case error.UNKNOWN_ERROR:
                    alert("An unknown error occurred.");
                    break;
                  default:
                    break;
                }
              };

              navigator.geolocation.getCurrentPosition(
                (position) =>
                  sendLocationStamp({
                    lat: String(position.coords.latitude),
                    lng: String(position.coords.longitude),
                  })
                    .unwrap()
                    .catch((err) => HandleErrors(err)),
                showError
              );
            }
          });
      })
      .catch((err) => HandleErrors(err));
  };

  return (
    <>
      <div className={styles.logoContainer}>
        <div className={styles.logoHolder}>
          <Image src={LOGO} />
        </div>
      </div>
      <Text className={styles.title}>Welcome Back!</Text>
      <Text className={styles.secondaryTitle}>Log in to continue</Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={onLoginHandler}
      >
        <Form.Item name="email" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="EMAIL" />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="PASSWORD" type="password" />
        </Form.Item>

        <Link className={styles.forgotPassword} to="/auth/forgot-password">
          Forgot Password?
        </Link>

        <Button
          btnClassName={styles.submitBtn}
          type="submit"
          isLoading={isLoading}
        >
          Log in
        </Button>
      </Form>
    </>
  );
}
