import { AuthLayoutProps } from "./types";
import "i18n";
import styles from "./styles.module.scss";

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <section>
      <div className={styles.container}>
        <div className={styles.formSection}>
          <div className={styles.formContainer}>{children}</div>
        </div>
      </div>
    </section>
  );
}
