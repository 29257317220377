import { Outlet, useRoutes } from "react-router-dom";

// Main Screens Imports
import Home from "pages/Home";
import Login from "pages/Auth/Login";
import AuthLayout from "components/wrappers/AuthLayout";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import ProtectedOutlet from "components/wrappers/ProtectedOutlet";
import SetYourNewPassword from "pages/Auth/SetYourNewPassword";
import HomeLayout from "components/wrappers/HomeLayout";
import VisitsHistory from "pages/VisitsHistory";
import MyProfile from "pages/MyProfile";
import PendingVisits from "pages/PendingVisits";
// End Main Screens Imports

export default function useGenerateRoutes() {
  const routes = [
    {
      path: "/",
      element: (
        <HomeLayout>
          <ProtectedOutlet />
        </HomeLayout>
      ),
      children: [
        // Main Screens
        {
          path: "",
          element: <Home />,
        },
        {
          path: "visits-history",
          element: <VisitsHistory />,
        },
        {
          path: "pending-visits",
          element: <PendingVisits />,
        },
        {
          path: "my-Profile",
          element: <MyProfile />,
        },
        // End Main Screens
      ],
    },
    {
      path: "/auth/",
      element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ),
      children: [
        {
          path: "",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "set-new-password",
          element: <SetYourNewPassword />,
        },
      ],
    },
  ];

  return useRoutes(routes);
}
