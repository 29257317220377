import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import { useResetPasswordMutation } from "apis/services/auth";
import showSuccessMsg from "utils/showSuccessMsg";
import HandleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

export default function SetYourNewPassword() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state?.email && !location.state?.code)
    <Navigate to="/auth/reset-password" />;

  const submitForm = (data: { password: string }) => {
    const finalStepData = {
      email: location.state?.email,
      code: location.state?.code,
      password: data.password,
    };

    resetPassword(finalStepData)
      .unwrap()
      .then(() => {
        showSuccessMsg({
          msg: "Your password has been reset successfly! Please login with new password.",
        });
        navigate("/auth");
      })
      .catch((err) => {
        HandleErrors(err);
      });
  };

  return (
    <>
      <Text className={styles.title}>Set your new Password</Text>
      <Text className={styles.secondaryTitle}>Enter your new password</Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={submitForm}
      >
        <Form.Item name="password" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="PASSWORD" type="password" />
        </Form.Item>

        <Form.Item name="confirm password" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="CONFIRMPASSWORD" type="password" />
        </Form.Item>

        <Button
          btnClassName={styles.submitBtn}
          type="submit"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </Form>
    </>
  );
}
