import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import { useNavigate } from "react-router-dom";
import { useGetResetCodeMutation } from "apis/services/auth";
import showSuccessMsg from "utils/showSuccessMsg";
import HandleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [getResetCode, { isLoading }] = useGetResetCodeMutation();

  const handleOnSubmit = (data: { email: string }) => {
    getResetCode(data)
      .unwrap()
      .then((res) => {
        if (res.details) showSuccessMsg({ msg: res?.details });
        navigate("/auth/reset-password", { state: { email: data.email } });
      })
      .catch((err) => {
        HandleErrors(err);
      });
  };
  return (
    <>
      <Text className={styles.title}>Forget password</Text>
      <Text className={styles.secondaryTitle}>
        Enter your email, and We&rsquo;ll send you a code by email to confirm
        your email.
      </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={handleOnSubmit}
      >
        <Form.Item name="email" rules={[{ required: true }, { type: "email" }]}>
          <TextInput i18nLabelKey="EMAIL" />
        </Form.Item>

        <Button
          btnClassName={styles.submitBtn}
          type="submit"
          isLoading={isLoading}
        >
          Reset Password
        </Button>
      </Form>
    </>
  );
}
