import { useEffect, useState } from "react";
import {
  useGetAllVisitsQuery,
  useSendLocationStampMutation,
} from "apis/services/visits";
import Text from "components/general/Text";
import UpcomingVisitCard from "components/cards/UpcomingVisitCard";
import InfiniteScroll from "react-infinite-scroll-component";
import useGetUserInfo from "hooks/useGetUserInfo";
import { DatePicker, Select } from "antd";
import styles from "./styles.module.scss";

export default function Home() {
  const { userInfo } = useGetUserInfo();
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projects, setProjects] = useState("");

  const { data: visits, isLoading } = useGetAllVisitsQuery({
    is_completed: false,
    page,
    scheduled_date_gte: startDate,
    scheduled_date_lte: endDate,
    project_id: projects,
  });

  const [sendLocationStamp] = useSendLocationStampMutation();

  useEffect(() => {
    if (userInfo?.role === "employee") {
      const showError = (error: any) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            alert(
              "This site's Location permission is denied. Please go to site settings and open location permission to be able to use this site"
            );
            break;
          case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            alert("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.");
            break;
          default:
            break;
        }
      };

      navigator.geolocation.getCurrentPosition(
        (position) =>
          sendLocationStamp({
            lat: String(position.coords.latitude),
            lng: String(position.coords.longitude),
          }),
        showError
      );
    }
  }, [userInfo?.role]);

  return (
    <div className={styles.container}>
      <Text className={styles.headerTitle}>Upcoming Visits</Text>
      <div className="mb-2">
        <Text>Select date range:</Text>
        <div className="d-flex gap-2">
          <DatePicker
            className="w-100"
            size="large"
            placeholder="Start Date"
            onChange={(_, stringVal) => setStartDate(stringVal)}
          />
          <DatePicker
            className="w-100"
            size="large"
            placeholder="End Date"
            onChange={(_, stringVal) => setEndDate(stringVal)}
          />
        </div>
      </div>
      <div className="mb-3">
        <Text>Select project:</Text>
        <Select
          mode="multiple"
          size="large"
          className="d-block"
          placeholder="Choose Project"
          options={userInfo?.projects.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          onChange={(vals) => setProjects(vals.join(","))}
        />
      </div>
      {visits?.results && (
        <InfiniteScroll
          dataLength={visits.results.length}
          next={() => {
            if (isLoading) return;
            if (!visits?.next) return;
            setPage(page + 1);
          }}
          hasMore={visits.next !== null}
          loader={<h4>Loading...</h4>}
        >
          {visits?.results?.map((visit) => (
            <UpcomingVisitCard key={visit.id} visit={visit} />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}
