import Text from "components/general/Text";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import HandleErrors from "utils/handleErrors";
import { useCheckResetCodeMutation } from "apis/services/auth";
import styles from "./styles.module.scss";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkResetCode, { isLoading }] = useCheckResetCodeMutation();

  if (!location.state?.email) <Navigate to="/auth/reset-password" />;

  const handleOnFinish = (data: { code: string }) => {
    const formBody = {
      email: location.state?.email,
      code: data.code,
    };

    checkResetCode(formBody)
      .unwrap()
      .then(() => {
        navigate("/auth/set-new-password", {
          state: { ...formBody },
        });
      })
      .catch((err) => {
        HandleErrors(err);
      });
  };

  return (
    <>
      <Text className={styles.title}>Reset Password</Text>
      <Text className={styles.secondaryTitle}>
        Enter your OTP code here to reset your password.
      </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={handleOnFinish}
      >
        <Form.Item name="code" rules={[{ required: true }, { type: "string" }]}>
          <TextInput i18nLabelKey="CODE" />
        </Form.Item>

        <Button
          btnClassName={styles.submitBtn}
          type="submit"
          isLoading={isLoading}
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
