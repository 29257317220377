import { useState } from "react";
import { Tooltip } from "antd";
import useGetUserInfo from "hooks/useGetUserInfo";
import Text from "components/general/Text";
import moment from "moment";
import useScreenSize from "hooks/useScreenSize";
import { saveAs } from "file-saver";
import Image from "../../general/Image";
import Button from "../../general/Button";
import Icon from "../../general/Icon";
import { VisitsHistoryCardProps } from "./types";
import styles from "./styles.module.scss";

export default function VisitsHistoryCard({
  containerStyle,
  visit,
}: VisitsHistoryCardProps) {
  const { isClient, isLeadEmployee } = useGetUserInfo();
  const [showDetails, setShowDetails] = useState(false);
  const { isMobile } = useScreenSize(0);

  const handleReportDownload = () => {
    if (visit.report) saveAs(visit.report, `visit-${visit.number}-report`);
  };

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={styles.locationAndPhoneBtns}>
        {visit.on_complete_map_stamp && (
          <Button
            btnClassName={styles.btn}
            onClick={() =>
              window.location.replace(
                `https://www.google.com/maps/search/?api=1&query=${visit?.on_complete_map_stamp?.lat},${visit?.on_complete_map_stamp?.lng}`
              )
            }
          >
            {isMobile ? (
              <div>
                <Icon name="location" size={20} color="primary" />
              </div>
            ) : (
              <Tooltip title="View visit mark on Google Maps">
                <div>
                  <Icon name="location" size={20} color="primary" />
                </div>
              </Tooltip>
            )}
          </Button>
        )}
        {!isClient && visit.location && visit.location?.phone && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <a className={styles.btn} href={`tel:${visit?.location?.phone}`}>
            {isMobile ? (
              <div>
                <Icon name="phone" size={20} color="primary" />
              </div>
            ) : (
              <Tooltip title="Call location number">
                <div>
                  <Icon name="phone" size={20} color="primary" />
                </div>
              </Tooltip>
            )}
          </a>
        )}
      </div>

      {isClient && visit.number && (
        <div className={styles.visitNumHeader}>
          <Text className={styles.title}>Visit Number:</Text>
          <Text className={styles.title}>#{visit.number}</Text>
        </div>
      )}

      {!isClient && (
        <div className={styles.companyLogoAndTitle}>
          {visit?.location?.project?.company_logo && (
            <div className={styles.logoContainer}>
              <Image
                src={visit?.location?.project?.company_logo}
                alt="company-logo"
              />
            </div>
          )}
          <Text className={styles.title}>
            {visit?.location?.project?.company_name}
          </Text>
        </div>
      )}

      <div
        className={`${styles.visitInfo} ${
          !isClient ? styles[showDetails ? "open" : "close"] : ""
        }`}
      >
        <div className={styles.infoRow}>
          <Text>Project:</Text>
          <Text>{visit?.location?.project?.name}</Text>
        </div>

        {!isClient && (
          <div className={styles.infoRow}>
            <Text>Order Number:</Text>
            <Text>#{visit.number}</Text>
          </div>
        )}

        <div className={styles.infoRow}>
          <Text>Scheduled Date:</Text>
          <Text>
            {visit?.scheduled_date &&
              moment(visit?.scheduled_date).format("DD MMMM YYYY - hh:mm a")}
          </Text>
        </div>
        <div className={styles.infoRow}>
          <Text>Completed Date:</Text>
          <Text>
            {visit?.scheduled_date &&
              moment(visit?.completed_date).format("DD MMMM YYYY - hh:mm a")}
          </Text>
        </div>
        <div className={styles.infoRow}>
          <Text>Location:</Text>
          <Text>{visit?.location?.name}</Text>
        </div>
        {!isClient && (
          <div className={styles.infoRow}>
            <Text>Phone:</Text>
            <Text>{visit?.location?.phone}</Text>
          </div>
        )}
      </div>

      <div
        className={`${styles.collapse} ${
          styles[showDetails ? "open" : "close"]
        }`}
      >
        <div className={styles.myNotes}>
          <Text>{!isClient ? "My Note" : "Employee Note"}</Text>
          <Text>{visit.note}</Text>
        </div>

        {(isClient || isLeadEmployee) && visit.report !== null && (
          <div className={styles.markBtnContainer}>
            <Button
              btnClassName={styles.markBtn}
              onClick={handleReportDownload}
            >
              Download Report
            </Button>
          </div>
        )}
      </div>
      <hr className={styles.divider} />
      <div className={styles.moreDetailsContainer}>
        <Button
          btnClassName={styles.moreDetailsBtn}
          onClick={() => setShowDetails(!showDetails)}
          suffix={<Icon name="arrowDown" size={24} color="primary" />}
        >
          {showDetails ? "Less Details" : "More Details"}
        </Button>
      </div>
    </div>
  );
}
