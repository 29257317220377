import Navbar from "components/general/Navbar";
import { HomeLayoutProps } from "./types";
import "i18n";
import styles from "./styles.module.scss";

export default function HomeLayout({ children }: HomeLayoutProps) {
  return (
    <div className={styles.container}>
      <Navbar />
      {children}
    </div>
  );
}
