import { useEffect } from "react";
import useGetUserInfo from "hooks/useGetUserInfo";
import Button from "components/general/Button";
import Text from "components/general/Text";
import logoutHandler from "utils/logoutHandler";
import { useSendLocationStampMutation } from "apis/services/visits";
import styles from "./styles.module.scss";

export default function MyProfile() {
  const { isClient, userInfo, isUserInfoLoading } = useGetUserInfo();

  const [sendLocationStamp] = useSendLocationStampMutation();

  useEffect(() => {
    if (userInfo?.role === "employee") {
      const showError = (error: any) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            alert(
              "This site's Location permission is denied. Please go to site settings and open location permission to be able to use this site"
            );
            break;
          case error.POSITION_UNAVAILABLE:
            alert("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            alert("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            alert("An unknown error occurred.");
            break;
          default:
            break;
        }
      };

      navigator.geolocation.getCurrentPosition(
        (position) =>
          sendLocationStamp({
            lat: String(position.coords.latitude),
            lng: String(position.coords.longitude),
          }),
        showError
      );
    }
  }, []);

  return (
    <div className={styles.container}>
      {isUserInfoLoading ? (
        <Text className={styles.headerTitle}>Loading...</Text>
      ) : (
        <>
          <Text className={styles.headerTitle}>My Profile</Text>

          <div className={styles.infoCol}>
            {isClient && (
              <Text className={styles.infoColtitle}>Personal Info</Text>
            )}

            <div className={styles.infoRow}>
              <Text className={styles.tilte}>Name:</Text>
              <Text className={styles.info}>
                {userInfo?.first_name !== ""
                  ? `${userInfo?.first_name} ${userInfo?.last_name}`
                  : userInfo?.username}
              </Text>
            </div>
            <div className={styles.infoRow}>
              <Text className={styles.tilte}>Title:</Text>
              <Text className={styles.info}>
                {userInfo?.title ?? userInfo?.role}
              </Text>
            </div>
            <div className={styles.infoRow}>
              <Text className={styles.tilte}>Phone Number:</Text>
              <Text className={styles.info}>{userInfo?.phone ?? ""}</Text>
            </div>
            <div className={styles.infoRow}>
              <Text className={styles.tilte}>E-mail:</Text>
              <Text className={styles.info}>{userInfo?.email}</Text>
            </div>
          </div>

          {isClient && (
            <>
              <hr className={styles.divider} />

              <div className={styles.infoCol}>
                <Text className={styles.infoColtitle}>Company Info</Text>

                <div className={styles.infoRow}>
                  <Text className={styles.tilte}>Name:</Text>
                  <Text className={styles.info}>{userInfo?.company?.name}</Text>
                </div>
                <div
                  className={`${styles.infoRow} ${styles.locationContainer}`}
                >
                  <Text className={styles.tilte}>Location:</Text>
                  <Text className={styles.info}>
                    {userInfo?.company?.location}
                  </Text>
                </div>
                <div className={styles.infoRow}>
                  <Text className={styles.tilte}>Phone Number:</Text>
                  <Text className={styles.info}>
                    {userInfo?.company?.phone}
                  </Text>
                </div>
              </div>
            </>
          )}

          <Button
            btnClassName={styles.logoutBtn}
            onClick={() => logoutHandler()}
          >
            Log Out
          </Button>
        </>
      )}
    </div>
  );
}
