import { useEffect, useState } from "react";
import { Tooltip, Upload, UploadProps } from "antd";
import useGetUserInfo from "hooks/useGetUserInfo";
import Text from "components/general/Text";
import moment from "moment";
import useScreenSize from "hooks/useScreenSize";
import { useUpdateVisitMutation } from "apis/services/visits";
import showSuccessMsg from "utils/showSuccessMsg";
import HandleErrors from "utils/handleErrors";
import Image from "../../general/Image";
import Button from "../../general/Button";
import Icon from "../../general/Icon";
import { PendingVisitCardProps } from "./types";
import styles from "./styles.module.scss";

export default function PendingVisitCard({
  containerStyle,
  visit,
}: PendingVisitCardProps) {
  const [pendingReasons, setPendingReasons] = useState<string[]>([]);
  const { isClient, isLeadEmployee } = useGetUserInfo();
  const { isMobile } = useScreenSize(0);

  const [updateVisit, { isLoading: isUpdateLoading }] =
    useUpdateVisitMutation();

  useEffect(() => {
    if (visit) {
      const newReasons = [];
      if (!visit.is_client_validated) {
        newReasons.push("Pending Client Validation");
      }
      if (visit.report === null) {
        newReasons.push("Pending Report Upload");
      }
      setPendingReasons(newReasons);
    }
  }, [visit]);

  const handelVisitApproval = () => {
    if (visit.id && isClient) {
      const data = new FormData();
      data.append("is_client_validated", String(true));
      updateVisit([visit.id, data]);
    } else {
      HandleErrors(["Something went wrong!"]);
    }
  };

  const onUploadChange: UploadProps["onChange"] = ({ fileList }) => {
    if (visit.id && isLeadEmployee) {
      const data = new FormData();
      data.append("report", fileList[0].originFileObj as unknown as Blob);
      updateVisit([visit.id, data])
        .unwrap()
        .then(() => showSuccessMsg({ msg: "Report uploaded successfly!" }));
    } else {
      HandleErrors(["Something went wrong!"]);
    }
  };

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      {!isClient && (
        <div className={styles.locationAndPhoneBtns}>
          {visit.location && visit.location.lat && visit.location.lng && (
            <Button
              btnClassName={styles.btn}
              onClick={() =>
                window.location.replace(
                  `https://www.google.com/maps/search/?api=1&query=${visit?.location?.lat},${visit?.location?.lng}`
                )
              }
            >
              {isMobile ? (
                <div>
                  <Icon name="location" size={20} color="primary" />
                </div>
              ) : (
                <Tooltip title="View location on Google Maps">
                  <div>
                    <Icon name="location" size={20} color="primary" />
                  </div>
                </Tooltip>
              )}
            </Button>
          )}
          {visit.location && visit.location.phone && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <a className={styles.btn} href={`tel:${visit?.location?.phone}`}>
              {isMobile ? (
                <div>
                  <Icon name="phone" size={20} color="primary" />
                </div>
              ) : (
                <Tooltip title="Call location number">
                  <div>
                    <Icon name="phone" size={20} color="primary" />
                  </div>
                </Tooltip>
              )}
            </a>
          )}
        </div>
      )}

      {isClient && visit.number && (
        <div className={styles.visitNumHeader}>
          <Text className={styles.title}>Visit Number:</Text>
          <Text className={styles.title}>#{visit?.number}</Text>
        </div>
      )}

      {!isClient && (
        <div className={styles.companyLogoAndTitle}>
          {visit?.location?.project?.company_logo && (
            <div className={styles.logoContainer}>
              <Image
                src={visit?.location?.project?.company_logo}
                alt="company-logo"
              />
            </div>
          )}
          <Text className={styles.title}>
            {visit?.location?.project?.company_name}
          </Text>
        </div>
      )}

      <div className={styles.visitInfo}>
        <div className={styles.infoRow}>
          <Text>Project:</Text>
          <Text>{visit?.location?.project?.name}</Text>
        </div>
        {!isClient && (
          <div className={styles.infoRow}>
            <Text>Order Number:</Text>
            <Text>#{visit.number}</Text>
          </div>
        )}
        <div className={styles.infoRow}>
          <Text>Date:</Text>
          <Text>
            {visit?.scheduled_date &&
              moment(visit?.scheduled_date).format("DD MMMM YYYY - hh:mm a")}
          </Text>
        </div>
        <div className={styles.infoRow}>
          <Text>Location:</Text>
          <Text>{visit?.location?.name}</Text>
        </div>
        {!isClient && (
          <div className={styles.infoRow}>
            <Text>Phone:</Text>
            <Text>{visit?.location?.phone}</Text>
          </div>
        )}
        {pendingReasons.length > 0 && (
          <div className={styles.infoRow}>
            <Text>Reason:</Text>
            <Text>{pendingReasons.join(", ")}</Text>
          </div>
        )}
      </div>

      {!isClient && isLeadEmployee && visit.report === null && (
        <>
          <hr className={styles.divider} />
          <div className={styles.cardBtnContainer}>
            <Upload
              onChange={onUploadChange}
              multiple={false}
              showUploadList={false}
              beforeUpload={() => false}
              disabled={isUpdateLoading}
            >
              <Button btnClassName={styles.cardBtn} isLoading={isUpdateLoading}>
                Upload Report
              </Button>
            </Upload>
          </div>
        </>
      )}

      {isClient && !visit.is_client_validated && (
        <>
          <hr className={styles.divider} />
          <div className={styles.cardBtnContainer}>
            <Button
              btnClassName={styles.cardBtn}
              onClick={handelVisitApproval}
              isLoading={isUpdateLoading}
            >
              Approve Visit
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
